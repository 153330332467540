<template>
  <el-dialog
      v-model="isShow"
      title="选择安装队伍"
      width="600px"
      :before-close="handleClose"
  >
    <el-form ref="formRef" :inline="true" :model="formState">
      <el-row>
        <el-col :span="12">
          <el-form-item prop="name" label="队伍名称">
            <el-input
                v-model="formState.name"
                placeholder="请输入队伍名称"
                clearable
                style="width: 240px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-button type="primary" @click="handleSearch">搜索</el-button>
            <el-button type="warning" @click="handleReset">刷新</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
        v-loading="loading"
        ref="table"
        :data="data"
        highlight-current-row
        @current-change="selectTeam"
        :default-sort="{ prop: 'count', order: 'descending' }"
    >
      <el-table-column property="select" label="选择" width="200">
        <template v-slot="scope">
          <!-- label值要与el-table数据id实现绑定 -->
          <el-radio v-model="selectId" :label="scope.row.id">{{ "" }}</el-radio>
        </template>
      </el-table-column>
      <el-table-column property="name" label="队伍名称" width="200"/>
      <el-table-column property="count" label="未完成安装任务数量" sortable width="200"/>
    </el-table>
    <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="handleSubmit">
            确定
          </el-button>
        </span>
    </template>
  </el-dialog>
</template>

<script>
import {findTeamList} from "@/api/team";
import {getTeamIncompleteTaskStatistic} from "@/api/installation_task";
import {isResOK} from "@/api/response";
import {findTeamUserList} from "@/api/userList";

export default {
  name: "SelectTeam",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    selectedTeam: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:visible', 'completed'],
  data() {
    return {
      data: [],
      res: {},
      selectId: 0,
      formState: {
        name: ''
      },
      initData: [],
      loading: false,
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      this.data = []
      this.selectId = this.selectedTeam.teamId
      this.res = this.selectedTeam
      let teamIncompleteTaskStatistic = {}
      findTeamUserList().then(teamData => {
        if (teamData.length > 0) {
          getTeamIncompleteTaskStatistic({teamIDs: this.$lodash.map(teamData, "id").join(",")}).then(res => {
            if (isResOK(res)) {
              teamIncompleteTaskStatistic = res.data
              teamData.forEach(team => {
                this.data.push({
                  id: team.id,
                  name: team.name,
                  count: teamIncompleteTaskStatistic[`${team.id}`] ? teamIncompleteTaskStatistic[`${team.id}`] : 0
                })
              })
              this.initData = this.data
            }
          }).finally(() => {
            this.loading = false
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },

    selectTeam(val) {
      this.res = {
        teamId: val.id,
        teamName: val.name,
      }
      this.selectId = val.id
    },

    handleClose() {
      this.isShow = false
    },

    handleSubmit() {
      this.$emit('completed', this.res)
      this.isShow = false
    },

    handleReset() {
      this.$refs.formRef.resetFields()
      this.data = this.initData
    },

    handleSearch() {
      let data = [];
      if(this.initData.length != 0 && this.formState.name){
        let str = `\S*${this.formState.name}\S*`;
        let reg = new RegExp(str);
        this.initData.map(item => {
          if(reg.test(item.name)){
            data.push(item);
          }
        })
      }
      this.data = data
    }
  }
}
</script>

<style scoped>

</style>
